@charset "utf-8";

// Brand colors
$grey-dark-1: #404040;
$grey-dark-2: #807f83;
$grey-light-1: #d7d6dc;
$grey-light-2: #f3f2f4;
$opta-orange: #d14f27;
$text-green: #42b34a;
$text-orange: #e86841;

// Update Bulma's global variables
$primary: $opta-orange;
$light: $grey-dark-2;

@import "../../node_modules/bulma/bulma.sass";

section.section{
    padding-top: 1rem;
    padding-bottom: 1rem;

    button.button:disabled{
        background-color: $grey-dark-2;
    }
}


